import { UpdateObservationAboutPatientDetails } from "./Model";

// Patient Demographics Loading State
export const UPDATE_OBSERVATION_DETAILS_LOADING = "UPDATE_OBSERVATION_DETAILS_LOADING";
export const UPDATE_OBSERVATION_DETAILS_SUCCESS = "UPDATE_OBSERVATION_DETAILS_SUCCESS";
export const UPDATE_OBSERVATION_DETAILS_FAIL = "UPDATE_OBSERVATION_DETAILS_FAIL";
export const UPDATE_OBSERVATION_DETAILS_UPDATE_API_MSG = "UPDATE_OBSERVATION_DETAILS_UPDATE_API_MSG";
export const UPDATE_OBSERVATION_DETAILS_CLEAR_API_RES = "UPDATE_OBSERVATION_DETAILS_CLEAR_API_RES";
export interface UpdateObservationAboutPatientDetailState {
  updateObservationAboutPatientDetailRes: UpdateObservationAboutPatientDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface updateObservationAboutPatientDetailLoading {
  type: typeof UPDATE_OBSERVATION_DETAILS_LOADING;
  loading: boolean;
}
export interface updateObservationAboutPatientDetailSuccess {
  type: typeof UPDATE_OBSERVATION_DETAILS_SUCCESS;
  successMsg: string;
  payload: UpdateObservationAboutPatientDetails;
}
export interface updateObservationAboutPatientDetailFail {
  type: typeof UPDATE_OBSERVATION_DETAILS_FAIL;
  payload: UpdateObservationAboutPatientDetails;
  errorMsg: string;
  status: number;
}
export interface updateObservationAboutPatientDetailUpdateAPIMsg {
  type: typeof UPDATE_OBSERVATION_DETAILS_UPDATE_API_MSG;
  payload: UpdateObservationAboutPatientDetails;
  errorMsg: string;
  status: number;
}
export interface updateObservationAboutPatientDetailUpdateAPIRes {
  type: typeof UPDATE_OBSERVATION_DETAILS_CLEAR_API_RES;
  payload: UpdateObservationAboutPatientDetails;
  errorMsg: string;
  status: number;
}

export type UpdateObservationAboutPatientDetailActionTypes =
  | updateObservationAboutPatientDetailLoading
  | updateObservationAboutPatientDetailSuccess
  | updateObservationAboutPatientDetailFail
  | updateObservationAboutPatientDetailUpdateAPIMsg
  | updateObservationAboutPatientDetailUpdateAPIRes;
