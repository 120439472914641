import {
  GetObservationAboutPatientDetailActionTypes,
  GET_OBSERVATION_DETAILS_FAIL,
  GET_OBSERVATION_DETAILS_LOADING,
  GET_OBSERVATION_DETAILS_SUCCESS,
  GetObservationAboutPatientDetailState,
  GET_OBSERVATION_DETAILS_UPDATE_API_MSG,
  GET_OBSERVATION_DETAILS_CLEAR_API_RES,
} from "./ActionTypes";
import { GetObservationAboutPatientDetail } from "./Model";

const initialStateGetPosts: GetObservationAboutPatientDetailState = {
  loading: false,
  getObservationAboutPatientDetailRes: {} as GetObservationAboutPatientDetail,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getObservationAboutPatientDetailsReducer = (
  state = initialStateGetPosts,
  action: GetObservationAboutPatientDetailActionTypes,
): GetObservationAboutPatientDetailState => {
  switch (action.type) {
    case GET_OBSERVATION_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        getObservationAboutPatientDetailRes: {} as GetObservationAboutPatientDetail,
      };
    case GET_OBSERVATION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getObservationAboutPatientDetailRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_OBSERVATION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        getObservationAboutPatientDetailRes: {} as GetObservationAboutPatientDetail,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_OBSERVATION_DETAILS_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        getObservationAboutPatientDetailRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_OBSERVATION_DETAILS_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        getObservationAboutPatientDetailRes: {} as GetObservationAboutPatientDetail,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
