
// import { GET_LIST } from "../types/ActionTypes";
import { PatientDetailsById } from "./Model";

export const GET_LIST = "GET_LIST";
export const PATIENT_DETAILS_LOADING =
  "PATIENT_DETAILS_LOADING";
export const PATIENT_DETAILS_SUCCESS =
  "PATIENT_DETAILS_SUCCESS";
export const PATIENT_DETAILS_FAIL =
  "PATIENT_DETAILS_FAIL";

export interface PatientDetailsState {
  patientDetailsById: PatientDetailsById;
  loading: boolean;
}
export interface PatientDetailsDataLoading {
  type: typeof PATIENT_DETAILS_LOADING;
}
export interface PatientDetailsDataSuccess {
  type: typeof PATIENT_DETAILS_SUCCESS;
  payload: PatientDetailsById;
}
export interface PatientDetailsDataFail {
  type: typeof PATIENT_DETAILS_FAIL;
}
interface PatientDetailsDataAction {
  type: typeof GET_LIST;
  payload: PatientDetailsById;
}

export type PatientDetailsActionType =
  | PatientDetailsDataAction
  | PatientDetailsDataLoading
  | PatientDetailsDataSuccess
  | PatientDetailsDataFail;
