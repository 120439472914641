import {
  GetPatientDetailsListActionTypes,
  GET_PATIENT_DETAILS_LIST_FAIL,
  GET_PATIENT_DETAILS_LIST_LOADING,
  GET_PATIENT_DETAILS_LIST_SUCCESS,
  GetPatientDetailsListState,
  GET_PATIENT_DETAILS_LIST_UPDATE_API_MSG,
  GET_PATIENT_DETAILS_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetPatientDetailsListResponse } from "./Model";

const initialStateGetPosts: GetPatientDetailsListState = {
  loading: false,
  getPatientDetailsListResponse: {} as GetPatientDetailsListResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getPatientDetailsListReducer = (
  state = initialStateGetPosts,
  action: GetPatientDetailsListActionTypes,
): GetPatientDetailsListState => {
  switch (action.type) {
    case GET_PATIENT_DETAILS_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_PATIENT_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        getPatientDetailsListResponse: action.payload,
        successMsg: action.successMsg,
      };
    case GET_PATIENT_DETAILS_LIST_FAIL:
      return {
        ...state,
        getPatientDetailsListResponse: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_DETAILS_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getPatientDetailsListResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_DETAILS_LIST_UPDATE_API_RES:
      return {
        ...state,
        getPatientDetailsListResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
