import { GetObservationAboutPatientDetail } from "./Model";

// Patient Demographics Loading State
export const GET_OBSERVATION_DETAILS_LOADING = "GET_OBSERVATION_DETAILS_LOADING";
export const GET_OBSERVATION_DETAILS_SUCCESS = "GET_OBSERVATION_DETAILS_SUCCESS";
export const GET_OBSERVATION_DETAILS_FAIL = "GET_OBSERVATION_DETAILS_FAIL";
export const GET_OBSERVATION_DETAILS_UPDATE_API_MSG = "GET_OBSERVATION_DETAILS_UPDATE_API_MSG";
export const GET_OBSERVATION_DETAILS_CLEAR_API_RES = "GET_OBSERVATION_DETAILS_CLEAR_API_RES";
export interface GetObservationAboutPatientDetailState {
  getObservationAboutPatientDetailRes: GetObservationAboutPatientDetail;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetObservationAboutPatientDetailLoading {
  type: typeof GET_OBSERVATION_DETAILS_LOADING;
  loading: boolean;
}
export interface GetObservationAboutPatientDetailSuccess {
  type: typeof GET_OBSERVATION_DETAILS_SUCCESS;
  successMsg: string;
  payload: GetObservationAboutPatientDetail;
}
export interface GetObservationAboutPatientDetailFail {
  type: typeof GET_OBSERVATION_DETAILS_FAIL;
  payload: GetObservationAboutPatientDetail;
  errorMsg: string;
  status: number;
}
export interface GetObservationAboutPatientDetailUpdateAPIMsg {
  type: typeof GET_OBSERVATION_DETAILS_UPDATE_API_MSG;
  payload: GetObservationAboutPatientDetail;
  errorMsg: string;
  status: number;
}
export interface GetObservationAboutPatientDetailUpdateAPIRes {
  type: typeof GET_OBSERVATION_DETAILS_CLEAR_API_RES;
  payload: GetObservationAboutPatientDetail;
  errorMsg: string;
  status: number;
}

export type GetObservationAboutPatientDetailActionTypes =
  | GetObservationAboutPatientDetailLoading
  | GetObservationAboutPatientDetailSuccess
  | GetObservationAboutPatientDetailFail
  | GetObservationAboutPatientDetailUpdateAPIMsg
  | GetObservationAboutPatientDetailUpdateAPIRes;
