import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetPatientDetailsListActionTypes } from "./ActionTypes";
import { GetPatientDetailsListResponse } from "./Model";
import {
  getPatientDetailsListAPIResClearAction,
  getPatientDetailsListErrorAction,
  getPatientDetailsListLoadingAction,
  getPatientDetailsListSuccessAction,
  getPatientDetailsListUpdateAPIMsg,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as GetPatientDetailsListResponse;
export const getPatientDetailsList = (competitionId: string, pageNo: any) => {
  return function (dispatch: Dispatch<GetPatientDetailsListActionTypes>) {
    dispatch(getPatientDetailsListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
        `/competitionapp/api/competitionPatientDetailsListAsJury/get_patientDetailsList_of_competition_as_jury?page=${Number(
          pageNo,
        )}&size=8`,
        { competitionId : "14" },
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientDetailsListLoadingAction(false));
        dispatch(getPatientDetailsListSuccessAction(res.data, "Patient Data Details has been fetched successfully"));
      })
      .catch((error) => {
        dispatch(getPatientDetailsListLoadingAction(false));
        dispatch(
          getPatientDetailsListErrorAction(
            {} as GetPatientDetailsListResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updatePatientDetailsListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientDetailsListActionTypes>) {
    dispatch(getPatientDetailsListUpdateAPIMsg(apiRes as GetPatientDetailsListResponse, "", "", 0));
  };
};

export const clearPatientDetailsListAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientDetailsListActionTypes>) {
    dispatch(getPatientDetailsListAPIResClearAction({} as GetPatientDetailsListResponse, "", "", 0));
  };
};
