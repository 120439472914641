import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReviewedGrid from "../GridView/ReviewedGrid";
import MWExceptionList from "../../component/MWExceptionList";
import { getJudgingSecondRoundDetailsList } from "../../redux/JudgingSecondRoundDetailsList/API";
import { GetJudgingSecondRoundDetailsListBody } from "../../redux/JudgingSecondRoundDetailsList/Model";

type Props = {
  loaderState: any;
};

export default function ReviewedPhotos({ loaderState }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const [alignment, setAlignment] = useState<string | null>("1");
  const handleAlignment = (_event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
  };
  const patientListBody = {
    competitionId: "14",
  } as GetJudgingSecondRoundDetailsListBody;

  useEffect(() => {
    dispatch(getJudgingSecondRoundDetailsList(patientListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const imageTableColumns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      width: 120,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            history.push(
              `/reviewed-image-details?&patientDetailsId=${params.row.id}`,
            );
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 450,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      minWidth: 300,
    },
  ];

  // Call task list store
  const patientPointDataList = useSelector((state: AppState) => state.get2ndRoundDetailsValue);

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    loaderState(patientPointDataList?.loading);
    if (
      patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList !== undefined
    ) {
      let dataPointArr =
        patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList.map(
          (element: any) => ({
            id: element.id,
            patientId: element.patientId,
            description:
              element.description !== undefined && element.description.indexOf(";") !== -1
                ? element.description.split(";")[1].split(":")[1]
                : "",
            startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY h:mm A"),
            date: element.startDate,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  const photographyDataTable = (
    <Container maxWidth="xl">
      <Card>
        <CardContent>
          {dataGridArray && dataGridArray.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={imageTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </Container>
  );

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" mt={2}>
        <Grid
          item
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" },
          }}
        >
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="1" aria-label="centered">
              <GridViewIcon />
            </ToggleButton>
            <ToggleButton value="2" aria-label="centered">
              <FormatListBulletedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        {alignment === "1" ? (
          <ReviewedGrid />
        ) : alignment === null ? (
          <ReviewedGrid />
        ) : (
          photographyDataTable
        )}
      </Stack>
    </>
  );
}
