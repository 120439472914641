import { combineReducers } from "redux";
import { logOutReducer } from "../logOut/Reducer";
import { jwtAuthTokenReducer } from "../jwtLogIn/Reducer";
import { adminLoginDetailsReducer } from "../jwtLogIn/loginDetails/Reducer";
import { getPatientDetailsByIdReducer } from "../patientDetailsById/Reducer";
import { getPatientDetailsListReducer } from "../GetPatientDetailsList/Reducer";
import { reviewNeededReducer } from "../reviewNeeded/Reducer";
import { simpleHistoryReducer } from "../SimpleHistory/Reducer";
import { updateObservationAboutPatientDetailsReducer } from "../updateObservationAboutPatientDetails/Reducer";
import { getObservationAboutPatientDetailsReducer } from "../getObservationAboutPatientDetails/Reducer";
import { getJudgingSecondRoundDetailsListReducer } from "../JudgingSecondRoundDetailsList/Reducer";

const Reducer = combineReducers({
  apiAuthToken: jwtAuthTokenReducer,
  adminLoginDetailsRes: adminLoginDetailsReducer,
  logOutRes: logOutReducer,
  getPatientDetailsById: getPatientDetailsByIdReducer,
  getPatientDataDetailsRes: getPatientDetailsListReducer,
  reviewNeeded: reviewNeededReducer,
  showHistory: simpleHistoryReducer,
  updateObservationValue: updateObservationAboutPatientDetailsReducer,
  getObservationValue: getObservationAboutPatientDetailsReducer,
  get2ndRoundDetailsValue: getJudgingSecondRoundDetailsListReducer,
});

export default Reducer;
