import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "./Login";
import LoginScreen from "../../image/featureimage-admin.png";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function LoginPageLayout() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          lg={6}
          xl={6}
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" },
          }}
        >
          <Box>
            <img
              src={LoginScreen}
              alt="loginimage"
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={6} xl={6}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: '100vh' }}
          ><Login /></Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
