import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

export default function AdminLoader() {
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          backgroundColor: "#f49537",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#db330c",
          },
        }}
      />
    </>
  );
}
