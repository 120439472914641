import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  useTheme,
  MobileStepper,
  Divider,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Tooltip,
  AlertColor,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import MWResponseBanner from "../../component/MWResponseBanner";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { getPatientDetailsList } from "../../redux/GetPatientDetailsList/API";
import { UpdateObservationAboutPatientDetailBody } from "../../redux/updateObservationAboutPatientDetails/Model";
import { updateObservationAboutPatientDetails } from "../../redux/updateObservationAboutPatientDetails/API";
import AdminLoader from "../../component/Loader";
import { GetObservationAboutPatientDetailBody } from "../../redux/getObservationAboutPatientDetails/Model";
import { getObservationAboutPatientDetails } from "../../redux/getObservationAboutPatientDetails/API";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useHistory, useLocation } from "react-router-dom";

const Controls = ({ zoomIn, zoomOut, resetTransform }: ReactZoomPanPinchContentRef) => (
  <Box>
    <Button onClick={() => zoomIn()}>+ Zoom in</Button>
    <Button onClick={() => zoomOut()}>- Zoom out</Button>
    <Button onClick={() => resetTransform()}>x Reset</Button>
  </Box>
);
const rating = [
  { label: "10", id: 1 },
  { label: "9", id: 2 },
  { label: "8", id: 3 },
  { label: "7", id: 4 },
  { label: "6", id: 5 },
  { label: "5", id: 6 },
  { label: "4", id: 7 },
  { label: "3", id: 8 },
  { label: "2", id: 9 },
  { label: "1", id: 10 },
] as const;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ImageDetails() {
  const dispatch = useDispatch();
  const history = useHistory();

  let query = useQuery();
  const pageNo = query.get("pageNo");
  const selectedPatientId = query.get("patientDetailsId");

  const pageCount = useSelector(
    (state: AppState) =>
      state.getPatientDataDetailsRes?.getPatientDetailsListResponse?.numberOfPages,
  );

  const patientPointDataList = useSelector((state: AppState) => state.getPatientDataDetailsRes);
  const getObservationDetailsValue = useSelector((state: AppState) => state.getObservationValue);
  const [bannerOpen, setBannerOpen] = useState(false);
  const [bannerColor, setBannerColor] = useState<AlertColor>("success");
  const [bannerMsg, setBannerMsg] = useState("");
  const updatedBannerState = (passedVal: boolean) => {
    setBannerOpen(passedVal);
  };
  const reviewNeededValue = useSelector((state: AppState) => state.updateObservationValue);
  const [observationComment, setObservationComment] = useState("");
  const [selectedItemValue, setItemSelected] = useState<any | null>(null);
  const ratingItemValue = (ratingValue: any, _e: any) => {
    setItemSelected(ratingValue.label);
  };
  const [patientID, setPatientID] = useState("");

  const submitUpdateObservation = () => {
    if (selectedItemValue === "" || selectedItemValue === undefined) {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg("Please rate the photograph");
    } else {
      let reviewNeededBody = {
        patientDetailsId: String(patientID),
        patientObservationValue: `photograding:${String(selectedItemValue)}`,
        patientObservationInterpretationComment: String(observationComment),
      } as UpdateObservationAboutPatientDetailBody;
      dispatch(updateObservationAboutPatientDetails(reviewNeededBody));
      setBannerOpen(false);
      setBannerMsg("");
    }
  };

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps =
    patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList?.length !== undefined &&
    patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList?.length > 0
      ? patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList.length
      : 0;

  const handleNext = (nextStep: number) => {
    if (activeStep === 7) {
      history.push(`/imagedetails?&pageNo=${Number(pageNo) + 1}`);
      dispatch(getPatientDetailsList("14", Number(pageNo) + 1));
      setBannerOpen(false);
    } else {
      history.push(`/imagedetails?&pageNo=${pageNo}&patientDetailsId=${nextStep}`);
      setPatientID(String(nextStep));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setItemSelected("");
      setObservationComment("");
      setBannerOpen(false);
    }
  };

  const handleBack = (preStep: number) => {
    if (activeStep === 0) {
      history.push(`/imagedetails?&pageNo=${Number(pageNo) - 1}`);
      dispatch(getPatientDetailsList("14", Number(pageNo) - 1));
      setBannerOpen(false);
    } else {
      history.push(`/imagedetails?&pageNo=${pageNo}&patientDetailsId=${preStep}`);
      setPatientID(String(preStep));
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setItemSelected("");
      setObservationComment("");
      setBannerOpen(false);
    }
  };

  const paginationHandleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`/imagedetails?&pageNo=${value}`);
    dispatch(getPatientDetailsList("14", value));
    setBannerOpen(false);
  };

  const dropdownPagehandleChange = (event: SelectChangeEvent) => {
    history.push(`/imagedetails?&pageNo=${event.target.value}`);
    dispatch(getPatientDetailsList("14", Number(event.target.value)));
    setBannerOpen(false);
  };

  useEffect(() => {
    if (selectedPatientId !== null) {
      if (patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList !== undefined) {
        if (patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList.length > 0) {
          let index =
            patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList.findIndex(
              (x) => x.id === Number(selectedPatientId),
            );
          setActiveStep(index);
        }
      }
    } else {
      setActiveStep(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList, selectedPatientId]);

  useEffect(() => {
    dispatch(getPatientDetailsList("14", pageNo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  useEffect(() => {
    if (reviewNeededValue?.successMsg !== "") {
      setBannerOpen(true);
      setBannerColor("success");
      setBannerMsg(reviewNeededValue?.successMsg);
      const getObservationDetailsBody = {
        patientDetailsId: String(patientID),
      } as GetObservationAboutPatientDetailBody;
      dispatch(getObservationAboutPatientDetails(getObservationDetailsBody));
    }
    if (reviewNeededValue?.errorMsg !== "") {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg(reviewNeededValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewNeededValue]);

  useEffect(() => {
    if (patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList !== undefined) {
      setPatientID(
        String(
          patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList[activeStep].id,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  useEffect(() => {
    if (patientID !== "") {
      const getObservationDetailsBody = {
        patientDetailsId: String(patientID),
      } as GetObservationAboutPatientDetailBody;
      dispatch(getObservationAboutPatientDetails(getObservationDetailsBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientID]);

  useEffect(() => {
    if (getObservationDetailsValue?.getObservationAboutPatientDetailRes !== undefined) {
      if (
        getObservationDetailsValue?.getObservationAboutPatientDetailRes
          ?.patientObservationInterpretationComment !== undefined
      ) {
        setObservationComment(
          getObservationDetailsValue?.getObservationAboutPatientDetailRes
            .patientObservationInterpretationComment,
        );
      } else {
        setObservationComment("No comment available");
      }

      setItemSelected(
        getObservationDetailsValue?.getObservationAboutPatientDetailRes.patientObservationValue,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getObservationDetailsValue]);

  return (
    <Box sx={{ marginTop: "64px" }}>
      {patientPointDataList?.loading || getObservationDetailsValue?.loading ? (
        <AdminLoader />
      ) : null}
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid sx={{ padding: "0px 20px" }} item xs={12} sm={12} md={6} lg={8} xl={8}>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Grid
              item
              sx={{
                display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
              }}
            >
              <Typography gutterBottom fontWeight="bold">
                {patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList !==
                undefined
                  ? patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList[
                      activeStep
                    ].description.indexOf(";") === -1
                    ? patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList[
                        activeStep
                      ].description
                        .split(";")[1]
                        .split(":")[1]
                    : ""
                  : ""}
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={9} sm={9} md={9} xl={3} lg={3}>
                <MobileStepper
                  variant="text"
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  sx={{ width: 250 }}
                  nextButton={
                    <>
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "none",
                            md: "none",
                            lg: "block",
                            xl: "block",
                          },
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() =>
                            handleNext(
                              activeStep < 7
                                ? patientPointDataList?.getPatientDetailsListResponse
                                    ?.patientDetailsList[activeStep + 1].id
                                : 0,
                            )
                          }
                          disabled={
                            pageCount === Number(pageNo) && activeStep === maxSteps - 1
                              ? true
                              : false
                          }
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{
                              textTransform: "none",
                            }}
                          >
                            Next
                          </Typography>
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          display: {
                            xs: "block",
                            sm: "block",
                            md: "block",
                            lg: "none",
                            xl: "none",
                          },
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            handleNext(
                              activeStep < 7
                                ? patientPointDataList?.getPatientDetailsListResponse
                                    ?.patientDetailsList[activeStep + 1].id
                                : 0,
                            )
                          }
                          disabled={
                            pageCount === Number(pageNo) && activeStep === maxSteps - 1
                              ? true
                              : false
                          }
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </Box>
                    </>
                  }
                  backButton={
                    <>
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "none",
                            md: "none",
                            lg: "block",
                            xl: "block",
                          },
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() =>
                            handleBack(
                              activeStep === 0
                                ? 0
                                : patientPointDataList?.getPatientDetailsListResponse
                                    ?.patientDetailsList[activeStep - 1].id,
                            )
                          }
                          disabled={Number(pageNo) === 1 && activeStep === 0 ? true : false}
                        >
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{
                              textTransform: "none",
                            }}
                          >
                            Previous
                          </Typography>
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          display: {
                            xs: "block",
                            sm: "block",
                            md: "block",
                            lg: "none",
                            xl: "none",
                          },
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            handleBack(
                              activeStep === 0
                                ? 0
                                : patientPointDataList?.getPatientDetailsListResponse
                                    ?.patientDetailsList[activeStep - 1].id,
                            )
                          }
                          disabled={Number(pageNo) === 1 && activeStep === 0 ? true : false}
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                      </Box>
                    </>
                  }
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
                }}
                xs={3}
                sm={3}
                md={3}
                xl={9}
                lg={9}
              >
                <FormControl sx={{ m: 1, minWidth: 60 }} size="small">
                  <InputLabel id="demo-select-small-label">Page</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={String(pageNo)}
                    label="Page"
                    onChange={dropdownPagehandleChange}
                    size="small"
                  >
                    {[...Array(pageCount)].map((x, i) => (
                      <MenuItem value={i + 1} key={i}>
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Box
                p={2}
                sx={{
                  display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" },
                }}
              >
                <Grid
                  spacing={1}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <Tooltip
                      title={
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            Patient Details{" "}
                            {patientPointDataList?.getPatientDetailsListResponse
                              ?.patientDetailsList !== undefined
                              ? patientPointDataList?.getPatientDetailsListResponse
                                  ?.patientDetailsList[activeStep].id
                              : ""}
                          </Grid>
                          <Grid item>
                            Patient Id{" "}
                            {patientPointDataList?.getPatientDetailsListResponse
                              ?.patientDetailsList !== undefined
                              ? patientPointDataList?.getPatientDetailsListResponse
                                  ?.patientDetailsList[activeStep].patientId
                              : ""}
                          </Grid>
                          <Grid item>
                            Document Id{" "}
                            {patientPointDataList?.getPatientDetailsListResponse
                              ?.patientDetailsList !== undefined
                              ? patientPointDataList?.getPatientDetailsListResponse
                                  ?.patientDetailsList[activeStep].patientDocument?.id
                              : ""}
                          </Grid>
                        </Grid>
                      }
                      arrow
                      placement="right"
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">Page:</Typography>
                  </Grid>
                  <Grid item>
                    {patientPointDataList?.getPatientDetailsListResponse?.numberOfPages > 0 ? (
                      <Pagination
                        color="primary"
                        count={
                          patientPointDataList?.getPatientDetailsListResponse?.numberOfPages > 0
                            ? Number(pageCount)
                            : 1
                        }
                        page={Number(pageNo)}
                        onChange={paginationHandleChange}
                        sx={{ flex: "center" }}
                        variant="outlined"
                        shape="rounded"
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Stack>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            enableMouseEvents
          >
            {patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList !== undefined
              ? patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList.map(
                  (step, index) => (
                    <div key={step.description}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <TransformWrapper
                          initialScale={1}
                          initialPositionX={0}
                          initialPositionY={0}
                        >
                          {(utils) => (
                            <React.Fragment>
                              <Box color="primary">
                                <Controls {...utils} />
                              </Box>
                              <TransformComponent
                                wrapperStyle={{
                                  maxWidth: "auto",
                                  height: "100%",
                                  border: "solid 2px gray",
                                }}
                              >
                                <img
                                  style={{
                                    height: "calc(100vh - 200px)",
                                    objectFit: "cover",
                                    width: "100%",
                                  }}
                                  src={step.patientDocument!.document}
                                  alt="photoimage"
                                />
                              </TransformComponent>
                            </React.Fragment>
                          )}
                        </TransformWrapper>
                      ) : null}
                    </div>
                  ),
                )
              : null}
            {}
          </SwipeableViews>
        </Grid>
        <Grid
          sx={{ background: "#f2f2f2", height: "100vh" }}
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={4}
        >
          <Box sx={{ padding: "24px" }}>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <Grid item>
                <b>
                  Title:{" "}
                  <i>
                    {patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList !==
                      undefined &&
                    patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList[
                      activeStep
                    ].description.indexOf(";") !== -1
                      ? patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList[
                          activeStep
                        ].description
                          .split(";")[1]
                          .split(":")[1]
                      : ""}
                  </i>
                </b>
              </Grid>
              <Grid item>
                <Button
                  color="info"
                  variant="contained"
                  href={
                    patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList !==
                      undefined &&
                    patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList[
                      activeStep
                    ].description.indexOf(";") !== -1
                      ? "https://" +
                        patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList[
                          activeStep
                        ].description
                          .split(";")[3]
                          .split(":")[1]
                      : ""
                  }
                  rel="noopener"
                  target="_blank"
                >
                  Open in Google Drive
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ borderBottom: 2, borderColor: "#c4c4c4", marginTop: 2 }}></Divider>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              sx={{ mt: 2 }}
            >
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Autocomplete
                      value={selectedItemValue || null}
                      id="combo-box-demo"
                      options={rating}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rate this Photograph"
                          placeholder="Rate this Photograph"
                        />
                      )}
                      disableClearable
                      onChange={(event: any, newValue: any) => {
                        ratingItemValue(newValue, event);
                      }}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                    />
                    <Box py={2}>
                      <TextField
                        value={observationComment}
                        id="outlined-multiline-static"
                        label="Comment"
                        onChange={(event: any) => setObservationComment(event?.target.value)}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </CardContent>
                  <Box p={1}>
                    <MWResponseBanner
                      bannerOpen={bannerOpen}
                      bannerColor={bannerColor}
                      responseMsg={bannerMsg}
                      bannerClose={updatedBannerState}
                    />
                  </Box>
                  <CardActions>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                      {reviewNeededValue?.loading ? (
                        <LoadingButton
                          loading={reviewNeededValue?.loading}
                          variant="contained"
                          fullWidth
                        >
                          Submit
                        </LoadingButton>
                      ) : (
                        <Button variant="contained" onClick={submitUpdateObservation} fullWidth>
                          Submit
                        </Button>
                      )}
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
