import {
  UPDATE_OBSERVATION_DETAILS_CLEAR_API_RES,
  UPDATE_OBSERVATION_DETAILS_FAIL,
  UPDATE_OBSERVATION_DETAILS_LOADING,
  UPDATE_OBSERVATION_DETAILS_SUCCESS,
  UPDATE_OBSERVATION_DETAILS_UPDATE_API_MSG,
  UpdateObservationAboutPatientDetailActionTypes,
} from "./ActionTypes";
import { UpdateObservationAboutPatientDetails } from "./Model";

export const updateObservationAboutPatientDetailLoadingAction = (
  loading: boolean,
): UpdateObservationAboutPatientDetailActionTypes => {
  return {
    type: UPDATE_OBSERVATION_DETAILS_LOADING,
    loading: loading,
  };
};

export const updateObservationAboutPatientDetailSuccessAction = (
  updateObservationAboutPatientDetailResponse: UpdateObservationAboutPatientDetails,
  successMsg: string,
  status: number,
): UpdateObservationAboutPatientDetailActionTypes => {
  return {
    type: UPDATE_OBSERVATION_DETAILS_SUCCESS,    
    payload: updateObservationAboutPatientDetailResponse,
    successMsg: successMsg,
  };
};

export const updateObservationAboutPatientDetailErrorAction = (
  updateObservationAboutPatientDetailResponse: UpdateObservationAboutPatientDetails,
  errMsg: string,
  status: number,
): UpdateObservationAboutPatientDetailActionTypes => {
  return {
    type: UPDATE_OBSERVATION_DETAILS_FAIL,
    payload: updateObservationAboutPatientDetailResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const updateObservationAboutPatientDetailUpdateAPIMsgAction = (
  updateObservationAboutPatientDetailResponse: UpdateObservationAboutPatientDetails,
  errMsg: string,
  status: number,
): UpdateObservationAboutPatientDetailActionTypes => {
  return {
    type: UPDATE_OBSERVATION_DETAILS_UPDATE_API_MSG,
    payload: updateObservationAboutPatientDetailResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const updateObservationAboutPatientDetailAPIResClearAction = (
  updateObservationAboutPatientDetailResponse: UpdateObservationAboutPatientDetails,
  errMsg: string,
  status: number,
): UpdateObservationAboutPatientDetailActionTypes => {
  return {
    type: UPDATE_OBSERVATION_DETAILS_CLEAR_API_RES,
    payload: updateObservationAboutPatientDetailResponse,
    errorMsg: errMsg,
    status: status,
  };
};
