import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { UpdateObservationAboutPatientDetailBody, UpdateObservationAboutPatientDetails } from "./Model";
import {
  updateObservationAboutPatientDetailAPIResClearAction,
  updateObservationAboutPatientDetailErrorAction,
  updateObservationAboutPatientDetailLoadingAction,
  updateObservationAboutPatientDetailSuccessAction,
  updateObservationAboutPatientDetailUpdateAPIMsgAction,
} from "./Actions";
import { UpdateObservationAboutPatientDetailActionTypes } from "./ActionTypes";
import { setupInterceptorsTo } from "../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as UpdateObservationAboutPatientDetails;
export const updateObservationAboutPatientDetails = (payload: UpdateObservationAboutPatientDetailBody) => {
  return function (dispatch: Dispatch<UpdateObservationAboutPatientDetailActionTypes>) {
    dispatch(updateObservationAboutPatientDetailLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/curatorapp/api/curator/updateObservationAboutPatientDetail`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(updateObservationAboutPatientDetailLoadingAction(false));
        dispatch(updateObservationAboutPatientDetailSuccessAction(
          res.data,
          res.data.message !== undefined ? res.data.message : "Patient modified successfully.",
          res.data.status,));
      })
      .catch((error) => {
        dispatch(updateObservationAboutPatientDetailLoadingAction(false));
        dispatch(
          updateObservationAboutPatientDetailErrorAction(
            {} as UpdateObservationAboutPatientDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateObservationAboutPatientDetailUpdateAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdateObservationAboutPatientDetailActionTypes>) {
    dispatch(updateObservationAboutPatientDetailUpdateAPIMsgAction(apiRes as UpdateObservationAboutPatientDetails, "", 0));
  };
};

export const clearupdateObservationAboutPatientDetailClearAPIRes = () => {
  return function (dispatch: Dispatch<UpdateObservationAboutPatientDetailActionTypes>) {
    dispatch(updateObservationAboutPatientDetailAPIResClearAction({} as UpdateObservationAboutPatientDetails, "", 0));
  };
};
