import { Box, Button, CardContent, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import MWExceptionList from "../../component/MWExceptionList";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { PatientDatapointList } from "../../redux/GetPatientDetailsList/Model";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function GridView() {
  const patientPointDataList = useSelector((state: AppState) => state.getPatientDataDetailsRes);
  const patientDetailsValue =
    patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList;
  const history = useHistory();

  let query = useQuery();
  const pageNo = query.get("pageNo");

  function photoDetails(patientId: number) {
    history.push(`/imagedetails?&pageNo=${pageNo}&patientDetailsId=${patientId}`);
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        py={1}
      >
        {patientDetailsValue !== undefined && patientDetailsValue.length ? (
          <React.Fragment>
            {patientDetailsValue?.map((photoItem: PatientDatapointList, index: number) => (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={index}>
                {photoItem?.id && (
                  <Card>
                    <CardMedia
                      component="img"
                      height="194"
                      image={
                        photoItem?.patientDocument?.document !== undefined
                          ? photoItem?.patientDocument?.document
                          : ""
                      }
                      alt="image"
                    />
                    <CardContent>
                      <Typography variant="body2" noWrap>
                        {photoItem.description !== undefined &&
                        photoItem.description.indexOf(";") !== -1
                          ? photoItem.description.split(";")[1].split(":")[1]
                          : ""}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid item>
                          <Button
                            variant="outlined"
                            sx={{
                              textTransform: "none",
                              borderColor: "#c4c4c4",
                              "&:hover": {
                                borderColor: "#c4c4c4",
                              },
                            }}
                            onClick={() => {
                              photoDetails(photoItem?.id);
                            }}
                          >
                            <Typography variant="subtitle1" fontWeight="bold">
                              Details
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                )}
              </Grid>
            ))}
          </React.Fragment>
        ) : (
          <Box p={3}>
            <MWExceptionList />
          </Box>
        )}
      </Grid>
    </Container>
  );
}
