import {
  GET_PATIENT_DETAILS_LIST_FAIL,
  GET_PATIENT_DETAILS_LIST_LOADING,
  GET_PATIENT_DETAILS_LIST_SUCCESS,
  GET_PATIENT_DETAILS_LIST_UPDATE_API_MSG,
  GET_PATIENT_DETAILS_LIST_UPDATE_API_RES,
  GetPatientDetailsListActionTypes,
} from "./ActionTypes";
import { GetPatientDetailsListResponse } from "./Model";

export const getPatientDetailsListLoadingAction = (loading: boolean): GetPatientDetailsListActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_LIST_LOADING,
    loading: loading,
  };
};

export const getPatientDetailsListSuccessAction = (
  getPatientDetailsListResponse: GetPatientDetailsListResponse,
  successMsg: string,
): GetPatientDetailsListActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_LIST_SUCCESS,
    payload: getPatientDetailsListResponse,
    successMsg: successMsg,
  };
};

export const getPatientDetailsListErrorAction = (
  getPatientDetailsListResponse: GetPatientDetailsListResponse,
  errMsg: string,
  status: number,
): GetPatientDetailsListActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_LIST_FAIL,
    payload: getPatientDetailsListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientDetailsListUpdateAPIMsg = (
  getPatientDetailsListResponse: GetPatientDetailsListResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientDetailsListActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_LIST_UPDATE_API_MSG,
    payload: getPatientDetailsListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientDetailsListAPIResClearAction = (
  getPatientDetailsListResponse: GetPatientDetailsListResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientDetailsListActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_LIST_UPDATE_API_RES,
    payload: getPatientDetailsListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
