import React, { useEffect, useState } from "react";
import { NavLink, BrowserRouter as Router, useHistory } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Grid,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import ComponentRouter from "./Routes";
import medicalWISDOM from "./image/logo/appLogoBlack.png";
import AdminLoader from "./component/Loader";
import { logOut } from "./redux/logOut/API";
import { LogOutBody } from "./redux/logOut/Model";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "./redux/store/Store";
import { GetAdminDetailsRes } from "./redux/jwtLogIn/loginDetails/Model";
import MoreIcon from "@mui/icons-material/MoreVert";

export default function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const Bearer = localStorage.getItem("access");
  const refresh = localStorage.getItem("refresh");
  let userDetails = JSON.parse(localStorage.getItem("loginDetails")!) as GetAdminDetailsRes;

  const logOutBody = {
    refresh_token: refresh!,
  } as LogOutBody;

  const handleLogout = () => {
    dispatch(logOut(logOutBody));
  };

  const logOutRes = useSelector((state: AppState) => state.logOutRes);
  useEffect(() => {
    setIsLoading(logOutRes?.loading);
  }, [logOutRes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToHome = () => {
    history.push(`/dashboard`);
    window.location.reload();
  };

  return (
    <Box>
      <Router>
        {Bearer ? (
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#ffffff",
            }}
          >
            <Toolbar>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                px={1}
              >
                <Grid item>
                  <img
                    alt="logo"
                    src={medicalWISDOM}
                    height="50px"
                    onClick={() => goToHome()}
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                      <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <Button
                          size="small"
                          aria-describedby={id}
                          onClick={handleClick}
                          sx={{ marginTop: 0.5 }}
                        >
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={0.5}
                          >
                            <Grid item>
                              <Avatar />
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                              >
                                <Grid item>
                                  <Typography
                                    sx={{ textTransform: "capitalize" }}
                                    variant="subtitle2"
                                    noWrap
                                  >
                                    {userDetails?.user?.first_name !== undefined &&
                                    userDetails?.user?.last_name !== undefined
                                      ? userDetails?.user?.first_name +
                                        " " +
                                        userDetails?.user?.last_name
                                      : null}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    noWrap
                                    variant="caption"
                                    style={{
                                      textTransform: "lowercase",
                                    }}
                                  >
                                    {userDetails?.user?.email !== undefined
                                      ? userDetails?.user?.email
                                      : null}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton
                          size="large"
                          aria-label="show more"
                          aria-haspopup="true"
                          onClick={handleClick}
                          sx={{ color: "#757575" }}
                        >
                          <MoreIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <List>
                  <ListItem component={NavLink} to="/signin">
                    <ListItemButton onClick={() => handleLogout()}>
                      <ListItemText>
                        <Typography color="#000000">Logout</Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </Toolbar>
          </AppBar>
        ) : null}

        <Box component="main">
          {Bearer ? (
            <Box>
              {loadingMarkup}
              <ComponentRouter />
            </Box>
          ) : (
            <ComponentRouter />
          )}
        </Box>
      </Router>
    </Box>
  );
}
