import React, { useEffect, useState } from "react";
import { Box, Chip, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import SelectedPhotos from "./SelectedPhotos";
import ReviewedPhotos from "./ReviewedPhotos";
import AdminLoader from "../../component/Loader";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  const [selected, setSelected] = useState(0);
  const patientPointDataList = useSelector((state: AppState) => state.getPatientDataDetailsRes);
  const secoundRoundList = useSelector((state: AppState) => state.get2ndRoundDetailsValue);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
  };
  const [loader, SetLoaderState] = useState(false);
  const [selectedPhoto, SetSelectedPhoto] = useState("");
  const [secondRoundPhoto, setSecondRoundPhoto] = useState("");
  const updateLoaderState = (loaderValue: boolean) => {
    SetLoaderState(loaderValue);
  };

  useEffect(() => {
    if (patientPointDataList?.getPatientDetailsListResponse?.patientDetailsList !== undefined) {
      SetSelectedPhoto(
        String(patientPointDataList?.getPatientDetailsListResponse?.count),
      );
    } else {
      SetSelectedPhoto("0");
    }
  }, [patientPointDataList]);

  useEffect(() => {
    if (secoundRoundList?.getJudgingSecondRoundDetailsListRes?.count !== undefined) {
      setSecondRoundPhoto(
        String(secoundRoundList?.getJudgingSecondRoundDetailsListRes?.count),
      );
    } else {
      setSecondRoundPhoto("0");
    }
  }, [secoundRoundList]);

  return (
    <Box mt={8}>
      {loader ? <AdminLoader /> : null}
      <Container maxWidth="xl">
        <Box pt={5} sx={{ borderBottom: 1, borderColor: "#f49537" }}>
          <Tabs
            value={selected}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#f49537",
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    {selected ? (
                      <Typography>Submitted Images</Typography>
                    ) : (
                      <Typography fontWeight="bold">Submitted Images</Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Chip label={selectedPhoto} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              {...a11yProps(0)}
            />
            
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    {selected ? (
                      <Typography fontWeight="bold">2nd Round Images</Typography>
                    ) : (
                      <Typography>2nd Round Images</Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Chip label={secondRoundPhoto} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={selected} index={0}>
          <SelectedPhotos loaderState={updateLoaderState} />
        </TabPanel>
        <TabPanel value={selected} index={1}>
          <ReviewedPhotos loaderState={updateLoaderState} />
        </TabPanel>
      </Container>
    </Box>
  );
}
