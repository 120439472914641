import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  useTheme,
  MobileStepper,
  Divider,
  Stack,
  AlertColor,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import MWResponseBanner from "../../component/MWResponseBanner";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { UpdateObservationAboutPatientDetailBody } from "../../redux/updateObservationAboutPatientDetails/Model";
import { updateObservationAboutPatientDetails } from "../../redux/updateObservationAboutPatientDetails/API";
import AdminLoader from "../../component/Loader";
import { GetObservationAboutPatientDetailBody } from "../../redux/getObservationAboutPatientDetails/Model";
import { getObservationAboutPatientDetails } from "../../redux/getObservationAboutPatientDetails/API";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";
import { useHistory, useLocation } from "react-router-dom";
// import { getJudgingSecondRoundDetailsList } from "../../redux/JudgingSecondRoundDetailsList/API";
// import { GetJudgingSecondRoundDetailsListBody } from "../../redux/JudgingSecondRoundDetailsList/Model";

const Controls = ({ zoomIn, zoomOut, resetTransform }: ReactZoomPanPinchContentRef) => (
  <Box>
    <Button onClick={() => zoomIn()}>+ Zoom in</Button>
    <Button onClick={() => zoomOut()}>- Zoom out</Button>
    <Button onClick={() => resetTransform()}>x Reset</Button>
  </Box>
);
const rating = [
  { label: "10", id: 1 },
  { label: "9", id: 2 },
  { label: "8", id: 3 },
  { label: "7", id: 4 },
  { label: "6", id: 5 },
  { label: "5", id: 6 },
  { label: "4", id: 7 },
  { label: "3", id: 8 },
  { label: "2", id: 9 },
  { label: "1", id: 10 },
] as const;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ReviewedImageDetails() {
  const dispatch = useDispatch();
  const history = useHistory();

  let query = useQuery();
  const selectedPatientId = query.get("patientDetailsId");

  const patientPointDataList = useSelector((state: AppState) => state.get2ndRoundDetailsValue);
  const getObservationDetailsValue = useSelector((state: AppState) => state.getObservationValue);
  const [bannerOpen, setBannerOpen] = useState(false);
  const [bannerColor, setBannerColor] = useState<AlertColor>("success");
  const [bannerMsg, setBannerMsg] = useState("");
  const updatedBannerState = (passedVal: boolean) => {
    setBannerOpen(passedVal);
  };
  const reviewNeededValue = useSelector((state: AppState) => state.updateObservationValue);
  const [observationComment, setObservationComment] = useState("");
  const [selectedItemValue, setItemSelected] = useState<any | null>(null);
  const ratingItemValue = (ratingValue: any, _e: any) => {
    setItemSelected(ratingValue.label);
  };
  const [patientID, setPatientID] = useState("");

  const submitUpdateObservation = () => {
    if (selectedItemValue === "" || selectedItemValue === undefined) {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg("Please rate the photograph");
    } else {
      let reviewNeededBody = {
        patientDetailsId: String(patientID),
        patientObservationValue: `photojudging:${String(selectedItemValue)}`,
        patientObservationInterpretationComment: String(observationComment),
      } as UpdateObservationAboutPatientDetailBody;
      dispatch(updateObservationAboutPatientDetails(reviewNeededBody));
      setBannerOpen(false);
      setBannerMsg("");
    }
  };

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps =
    patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList?.length !==
      undefined &&
    patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList?.length > 0
      ? patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList?.length
      : 0;

  const handleNext = (nextStep: number) => {
    history.push(`/reviewed-image-details?&patientDetailsId=${nextStep}`);
    setPatientID(String(nextStep));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setItemSelected("");
    setObservationComment("");
    setBannerOpen(false);
  };

  const handleBack = (preStep: number) => {
    history.push(`/reviewed-image-details?&patientDetailsId=${preStep}`);
    setPatientID(String(preStep));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setItemSelected("");
    setObservationComment("");
    setBannerOpen(false);
  };

  // const patientListBody = {
  //   competitionId: "14",
  // } as GetJudgingSecondRoundDetailsListBody;

  // useEffect(() => {
  //   dispatch(getJudgingSecondRoundDetailsList(patientListBody));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  useEffect(() => {
    if (selectedPatientId !== null) {
      if (
        patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList !== undefined
      ) {
        if (
          patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList?.length > 0
        ) {
          let index =
            patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList?.findIndex(
              (x) => x.id === Number(selectedPatientId),
            );
          console.log("index", index);
          setActiveStep(index);
        }
      }
    } else {
      setActiveStep(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList, selectedPatientId]);

  useEffect(() => {
    if (reviewNeededValue?.successMsg !== "") {
      setBannerOpen(true);
      setBannerColor("success");
      setBannerMsg(reviewNeededValue?.successMsg);
      const getObservationDetailsBody1 = {
        patientDetailsId: String(selectedPatientId),
        patientObservationKey: "photojudging",
      } as GetObservationAboutPatientDetailBody;
      dispatch(getObservationAboutPatientDetails(getObservationDetailsBody1));
    }
    if (reviewNeededValue?.errorMsg !== "") {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg(reviewNeededValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewNeededValue]);

  useEffect(() => {
    if (
      patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList !== undefined
    ) {
      setPatientID(
        String(
          patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList[activeStep]
            .id,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  useEffect(() => {
    if (patientID !== "") {
      const getObservationDetailsBody = {
        patientDetailsId: String(patientID),
        patientObservationKey: "photojudging",
      } as GetObservationAboutPatientDetailBody;
      dispatch(getObservationAboutPatientDetails(getObservationDetailsBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientID]);

  useEffect(() => {
    if (getObservationDetailsValue?.getObservationAboutPatientDetailRes !== undefined) {
      if (
        getObservationDetailsValue?.getObservationAboutPatientDetailRes
          ?.patientObservationInterpretationComment !== undefined
      ) {
        setObservationComment(
          getObservationDetailsValue?.getObservationAboutPatientDetailRes
            .patientObservationInterpretationComment,
        );
      } else {
        setObservationComment("No comment available");
      }

      setItemSelected(
        getObservationDetailsValue?.getObservationAboutPatientDetailRes.patientObservationValue,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getObservationDetailsValue]);

  return (
    <Box sx={{ marginTop: "64px" }}>
      {patientPointDataList?.loading || getObservationDetailsValue?.loading ? (
        <AdminLoader />
      ) : null}
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid sx={{ padding: "0px 20px" }} item xs={12} sm={12} md={6} lg={8} xl={8}>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Grid
              item
              sx={{
                display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
              }}
            >
              <Typography gutterBottom fontWeight="bold">
                {patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList !==
                undefined
                  ? patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList[
                      activeStep
                    ].description.indexOf(";") === -1
                    ? patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList[
                        activeStep
                      ].description
                        .split(";")[1]
                        .split(":")[1]
                    : ""
                  : ""}
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={9} sm={9} md={9} xl={3} lg={3}>
                <MobileStepper
                  variant="text"
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  sx={{ width: 300 }}
                  nextButton={
                    <>
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "none",
                            md: "none",
                            lg: "block",
                            xl: "block",
                          },
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() =>
                            handleNext(
                              patientPointDataList?.getJudgingSecondRoundDetailsListRes
                                ?.patientDetailsList[activeStep + 1].id,
                            )
                          }
                          disabled={activeStep === maxSteps - 1}
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{
                              textTransform: "none",
                            }}
                          >
                            Next
                          </Typography>
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          display: {
                            xs: "block",
                            sm: "block",
                            md: "block",
                            lg: "none",
                            xl: "none",
                          },
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            handleNext(
                              patientPointDataList?.getJudgingSecondRoundDetailsListRes
                                ?.patientDetailsList[activeStep + 1].id,
                            )
                          }
                          disabled={activeStep === maxSteps - 1}
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </Box>
                    </>
                  }
                  backButton={
                    <>
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "none",
                            md: "none",
                            lg: "block",
                            xl: "block",
                          },
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() =>
                            handleBack(
                              patientPointDataList?.getJudgingSecondRoundDetailsListRes
                                ?.patientDetailsList[activeStep - 1].id,
                            )
                          }
                          disabled={activeStep === 0}
                        >
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{
                              textTransform: "none",
                            }}
                          >
                            Previous
                          </Typography>
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          display: {
                            xs: "block",
                            sm: "block",
                            md: "block",
                            lg: "none",
                            xl: "none",
                          },
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            handleBack(
                              patientPointDataList?.getJudgingSecondRoundDetailsListRes
                                ?.patientDetailsList[activeStep - 1].id,
                            )
                          }
                          disabled={activeStep === 0}
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                      </Box>
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Stack>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            enableMouseEvents
          >
            {patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList !==
            undefined
              ? patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList.map(
                  (step, index) => (
                    <div key={step.description}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <TransformWrapper
                          initialScale={1}
                          initialPositionX={0}
                          initialPositionY={0}
                        >
                          {(utils) => (
                            <React.Fragment>
                              <Box color="primary">
                                <Controls {...utils} />
                              </Box>
                              <TransformComponent
                                wrapperStyle={{
                                  maxWidth: "auto",
                                  height: "100%",
                                  border: "solid 2px gray",
                                }}
                              >
                                <img
                                  style={{
                                    height: "calc(100vh - 200px)",
                                    objectFit: "cover",
                                    width: "100%",
                                  }}
                                  src={step.patientDocument!.document}
                                  alt="photoimage"
                                />
                              </TransformComponent>
                            </React.Fragment>
                          )}
                        </TransformWrapper>
                      ) : null}
                    </div>
                  ),
                )
              : null}
            {}
          </SwipeableViews>
        </Grid>
        <Grid
          sx={{ background: "#f2f2f2", height: "100vh" }}
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={4}
        >
          <Box sx={{ padding: "24px" }}>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <Grid item>
                <b>
                  Title:{" "}
                  <i>
                    {patientPointDataList?.getJudgingSecondRoundDetailsListRes
                      ?.patientDetailsList !== undefined &&
                    patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList[
                      activeStep
                    ].description.indexOf(";") !== -1
                      ? patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList[
                          activeStep
                        ].description
                          .split(";")[1]
                          .split(":")[1]
                      : ""}
                  </i>
                </b>
              </Grid>
              <Grid item>
                <Button
                  color="info"
                  variant="contained"
                  href={
                    patientPointDataList?.getJudgingSecondRoundDetailsListRes
                      ?.patientDetailsList !== undefined &&
                    patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList[
                      activeStep
                    ].description.indexOf(";") !== -1
                      ? "https://" +
                        patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList[
                          activeStep
                        ].description
                          .split(";")[3]
                          .split(":")[1]
                      : ""
                  }
                  rel="noopener"
                  target="_blank"
                >
                  Open in Google Drive
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ borderBottom: 2, borderColor: "#c4c4c4", marginTop: 2 }}></Divider>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              sx={{ mt: 2 }}
            >
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Autocomplete
                      value={selectedItemValue || null}
                      id="combo-box-demo"
                      options={rating}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rate this Photograph"
                          placeholder="Rate this Photograph"
                        />
                      )}
                      disableClearable
                      onChange={(event: any, newValue: any) => {
                        ratingItemValue(newValue, event);
                      }}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                    />
                    <Box py={2}>
                      <TextField
                        value={observationComment}
                        id="outlined-multiline-static"
                        label="Comment"
                        onChange={(event: any) => setObservationComment(event?.target.value)}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </CardContent>
                  <Box p={1}>
                    <MWResponseBanner
                      bannerOpen={bannerOpen}
                      bannerColor={bannerColor}
                      responseMsg={bannerMsg}
                      bannerClose={updatedBannerState}
                    />
                  </Box>
                  <CardActions>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                      {reviewNeededValue?.loading ? (
                        <LoadingButton
                          loading={reviewNeededValue?.loading}
                          variant="contained"
                          fullWidth
                        >
                          Submit
                        </LoadingButton>
                      ) : (
                        <Button variant="contained" onClick={submitUpdateObservation} fullWidth>
                          Submit
                        </Button>
                      )}
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
