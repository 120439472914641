import { DarkTheme as DefaultDarkTheme } from "./theme/index";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const DarkTheme = createTheme({
  ...DefaultDarkTheme,
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: "0px",
          minHeight: "40px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          alignItems: "start",
          justifyContent: "start",
          textAlign: "left",
          textTransform: "none",
          minHeight: 0,
          padding: "10px 20px",
          "&.Mui-selected": {
            color: "#db330c",
            fontWeight: "bold",
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
  },
});

const mwTheme = responsiveFontSizes(DarkTheme);
export default mwTheme;
