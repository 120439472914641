import { PaletteOptions } from "@mui/material";

export const darkPalette: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#000000",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#FFA732",
    contrastText: "#fff",
  },
  background: {
    default: "#ffffff",
    paper: "#ffffff",
  },
  success: {
    main: "#67be23",
    contrastText: "#fff",
  },
  error: {
    main: "#ee2a1e",
    contrastText: "#fff",
  },
  warning: {
    main: "#fa8c16",
    contrastText: "#fff",
  },
  info: {
    main: "#1890ff",
    contrastText: "#fff",
  },
  divider: "#373737",
  text: {
    primary: "#000000",
    secondary: "#000000",
    disabled: "#d1d1d1",
  },
};
