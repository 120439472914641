import React from "react";
import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "./routes/PublicRoute";
import { PrivateRoute } from "./routes/PrivateRoute";
// import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard/index";
import ImageDetails from "./pages/ImageDetails/index";
import NotFound from "./pages/NotFound";
import LoginPageLayout from "./pages/LoginPageLayout";
import ReviewedImageDetails from "./pages/ImageDetails/ReviewedImageDetails";

function ComponentRouter() {
  return (
    <Switch>
      <PublicRoute exact path="/" component={LoginPageLayout} />
      <PublicRoute exact path="/signin" component={LoginPageLayout} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/imagedetails" component={ImageDetails} />
      <PrivateRoute path="/reviewed-image-details" component={ReviewedImageDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default ComponentRouter;
