import {
  UpdateObservationAboutPatientDetailActionTypes,
  UPDATE_OBSERVATION_DETAILS_FAIL,
  UPDATE_OBSERVATION_DETAILS_LOADING,
  UPDATE_OBSERVATION_DETAILS_SUCCESS,
  UpdateObservationAboutPatientDetailState,
  UPDATE_OBSERVATION_DETAILS_UPDATE_API_MSG,
  UPDATE_OBSERVATION_DETAILS_CLEAR_API_RES,
} from "./ActionTypes";
import { UpdateObservationAboutPatientDetails } from "./Model";

const initialStateGetPosts: UpdateObservationAboutPatientDetailState = {
  loading: false,
  updateObservationAboutPatientDetailRes: {} as UpdateObservationAboutPatientDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const updateObservationAboutPatientDetailsReducer = (
  state = initialStateGetPosts,
  action: UpdateObservationAboutPatientDetailActionTypes,
): UpdateObservationAboutPatientDetailState => {
  switch (action.type) {
    case UPDATE_OBSERVATION_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        updateObservationAboutPatientDetailRes: {} as UpdateObservationAboutPatientDetails,
      };
    case UPDATE_OBSERVATION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateObservationAboutPatientDetailRes: action.payload,
        successMsg: action.successMsg,
      };
    case UPDATE_OBSERVATION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        updateObservationAboutPatientDetailRes: {} as UpdateObservationAboutPatientDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_OBSERVATION_DETAILS_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        updateObservationAboutPatientDetailRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_OBSERVATION_DETAILS_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        updateObservationAboutPatientDetailRes: {} as UpdateObservationAboutPatientDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
