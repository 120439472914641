import { GetPatientDetailsListResponse } from "./Model";
export const GET_PATIENT_DETAILS_LIST_LOADING = "GET_PATIENT_DETAILS_LIST_LOADING";
export const GET_PATIENT_DETAILS_LIST_SUCCESS = "GET_PATIENT_DETAILS_LIST_SUCCESS";
export const GET_PATIENT_DETAILS_LIST_FAIL = "GET_PATIENT_DETAILS_LIST_FAIL";
export const GET_PREFERENCE_DATA = "GET_PREFERENCE_DATA";
export const GET_PATIENT_DETAILS_LIST_UPDATE_API_MSG = "GET_PATIENT_DETAILS_LIST_UPDATE_API_MSG";
export const GET_PATIENT_DETAILS_LIST_UPDATE_API_RES = "GET_PATIENT_DETAILS_LIST_UPDATE_API_RES";

export interface GetPatientDetailsListState {
  getPatientDetailsListResponse: GetPatientDetailsListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsListLoading {
  type: typeof GET_PATIENT_DETAILS_LIST_LOADING;
  loading: boolean;
}
export interface GetPatientDetailsListSuccess {
  type: typeof GET_PATIENT_DETAILS_LIST_SUCCESS;
  payload: GetPatientDetailsListResponse;
  successMsg: string;
}
export interface GetPatientDetailsListFail {
  type: typeof GET_PATIENT_DETAILS_LIST_FAIL;
  payload: GetPatientDetailsListResponse;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsListUpdateAPIMsg {
  type: typeof GET_PATIENT_DETAILS_LIST_UPDATE_API_MSG;
  payload: GetPatientDetailsListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsListUpdateAPIRes {
  type: typeof GET_PATIENT_DETAILS_LIST_UPDATE_API_RES;
  payload: GetPatientDetailsListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientDetailsListActionTypes =
  | GetPatientDetailsListLoading
  | GetPatientDetailsListSuccess
  | GetPatientDetailsListFail
  | GetPatientDetailsListUpdateAPIMsg
  | GetPatientDetailsListUpdateAPIRes;
