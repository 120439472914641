import React from "react";
import { Box } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";

const MWTextField = (props: TextFieldProps) => {
  return (
    <Box>
      <TextField {...props} />
    </Box>
  );
};

export default MWTextField;
